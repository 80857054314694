import { createJSONStorage, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

export type SessionState = {
    preCode: string;
    volCode: string;
};

export type SessionActions = {
    setPreCode: (code: string) => void;
    setVolCode: (code: string) => void;
};

export type SessionStore = SessionState & SessionActions;

export const initSessionStore = (): SessionState => {
    return { preCode: '', volCode: '' };
};

export const defaultInitState: SessionState = {
    preCode: '',
    volCode: '',
};

export const createSessionStore = (initState: SessionState = defaultInitState) => {
    return createStore<SessionStore>()(
        persist(
            (set) => ({
                ...initState,
                setPreCode: (code: string) =>
                    set(() => ({
                        preCode: code,
                    })),
                setVolCode: (code: string) =>
                    set(() => ({
                        volCode: code,
                    })),
            }),
            {
                name: '_session',
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
    );
};
